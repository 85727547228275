<template>
  <div v-if="dealer" class="world products">
    <div class="banner-wrapper">
      <div class="banner">
        <div class="container">
          <div class="date">{{ date }}</div>
          <h1>{{ $t('Webshop Wilms Marketingmateriaal') }}</h1>
          <div class="intro-text" v-if="globals.intro_text">
            <div v-html="getTranslation(globals.intro_text).value"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="products-header">
        <div class="heading status">
          <div>
            <h3>{{ $t('Marketingbudget') }}</h3>
            <div class="value">{{ $n(parseFloat(dealer.b2b_order_budget), 'currency', 'nl-BE') }}</div>
          </div>
        </div>

        <div class="heading dealer">
          <div>
            <h3>{{ $t('Dealerinformatie') }}</h3>
            <div class="value">
              <span>{{ dealer.name }}</span>
              <div class="info-button">
                <img alt="Info icon" src="@/assets/img/info-icon.svg">
                <div class="info-content-wrapper">
                  <div class="info-content">
                    <div>{{ dealer.address.address1 }}</div>
                    <div>{{ dealer.address.postal_code }} {{ dealer.address.locality }}</div>

                    <div class="phone">{{ dealer.phone }}</div>
                    <div>{{ dealer.email }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RouterLink v-if="shoppingCart" :to="{ name: 'ww.cart' }" class="heading cart">
          <div>
            <h3>{{ $t('Winkelwagen') }}</h3>
            <div class="value">{{ Object.keys(shoppingCart).length }} {{ Object.keys(shoppingCart).length === 1 ? $t('product') : $t('producten') }} ({{ $n(shoppingCartTotal, 'currency', 'nl-BE') }})</div>
          </div>
        </RouterLink>
      </div>
    </div>

    <div class="container">
      <div class="products-list-wrapper">
        <div class="products-title">
          <h2>{{ $t('Producten') }}</h2>

          <div class="actions">
            <formulate-input v-model="search" :placeholder="$t('Zoeken')" type="text" @input="searchProducts"></formulate-input>
            <a :href="catalogueUrl" class="btn btn-download">{{ $t('Download de catalogus') }}</a>
          </div>
        </div>

        <div v-if="!loading && products && products.length > 0" class="products-list">
          <div v-for="product in products" :key="product.id" class="product">
            <div class="images">
              <swiper v-if="images && images.length > 0 && product.images && product.images.length > 0" ref="swiper" :pagination="{ clickable: true }">
                <swiper-slide v-for="productImage in product.images" :key="productImage">
                  <img v-if="images[productImage] && images[productImage].url" :src="images[productImage].url" alt="B2B product afbeelding">
                </swiper-slide>
              </swiper>
              <div v-else class="placeholder">
                <img alt="Placeholder" src="@/assets/img/placeholder-image.svg" width="74">
                <p>{{ $t('Geen foto voor dit product.') }}</p>
              </div>
            </div>
            <div class="title">
              <h3>{{ getTranslation(product).name }}</h3>
              <div class="unit-price">{{ $n(parseFloat(product.unit_price), 'currency', 'nl-BE') }}</div>
            </div>
            <div class="description" v-html="getTranslation(product).description"></div>
            <div class="order-actions">
              <div class="amount-wrapper">
                <div class="amount-input">
                  <button :class="{ 'disabled': product.amount === product.minimal_total_order_amount }" class="min" @click="minAmount(product)">
                    <img alt="" src="@/assets/img/min.svg"></button>
                  <div class="amount">{{ product.amount }}</div>
                  <button class="plus" @click="plusAmount(product)"><img alt="" src="@/assets/img/plus.svg"></button>
                </div>
                <div class="minimum">{{ $t('Min.') }} {{ product.minimal_total_order_amount }}</div>
              </div>

              <button class="btn btn-order" @click="addProduct(product)">{{ $n(parseFloat(product.unit_price * product.amount), 'currency', 'nl-BE') }} | {{ $t('Toevoegen aan winkelwagen') }}</button>
            </div>
          </div>
        </div>

        <div class="products-pagination">
          <button v-for="p in totalPages" :key="p" :class="{ 'active': p === page + 1 }" class="btn btn-page" @click="nextPage(p)">{{ p }}</button>
        </div>
      </div>
    </div>

    <div v-if="globals.fds_webshop_link" class="fds-wrapper">
      <div class="container">
        <div class="info" v-html="getTranslation(globals.fds_webshop_text).value"></div>
        <div class="link" v-html="getTranslation(globals.fds_webshop_link).value"></div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { ApiService } from '@/services/admin/api.service'
import { notification } from '@/notifications'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import 'swiper/swiper-bundle.css'
import { Pagination } from 'swiper'

SwiperCore.use([Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      loading: false,
      products: [],
      page: 0,
      total: 0,
      per_page: 27,
      search: '',
      shoppingCart: {},
      shoppingCartTotal: 0,
      images: [],
      imageIds: [],
      catalogueGlobal: null,
      catalogueUrl: null,
      swiperOptions: {
        autoHeight: false,
        pagination: {
          clickable: true,
          el: '.swiper-pagination'
        }
      },
      globals: {
        fds_webshop_text: null,
        fds_webshop_link: null,
        intro_text: null
      }
    }
  },
  async mounted () {
    await this.setDealer()
    await this.getProducts()
    await this.getCatalogue()

    await this.getShoppingCart()

    this.globals.fds_webshop_text = (await ApiService.fetchGlobal('products', 'fds_webshop_text')).data
    this.globals.fds_webshop_link = (await ApiService.fetchGlobal('products', 'fds_webshop_link')).data
    this.globals.intro_text = (await ApiService.fetchGlobal('products', 'intro_text')).data
  },
  methods: {
    async searchProducts () {
      this.page = 0
      await this.getProducts()
    },
    async getCatalogue () {
      const res = await ApiService.fetchGlobal('products', 'b2b_catalogue_file_id')
      if (res.status === 200) {
        const fileId = this.getTranslation(res.data).value
        const fileRes = await ApiService.getFile(fileId)
        if (fileRes.status === 200) {
          this.catalogueUrl = fileRes.data
        }
      }
    },
    async getProducts () {
      this.loading = true
      const res = await ApiService.fetchProducts({
        lang: this.activeLocale,
        page: this.page,
        order_by: 'product.name',
        per_page: this.per_page,
        search: this.search
      })

      this.products = res.data.products.map(product => {
        product.amount = product.minimal_total_order_amount
        if (Array.isArray(product.images)) {
          this.imageIds = this.imageIds.concat(product.images)
        }
        return product
      })

      if (this.imageIds.length > 0) {
        const imgRes = await ApiService.getFiles(this.imageIds.toString())

        if (imgRes.status === 200) {
          imgRes.data.files.forEach((obj) => {
            this.images[obj.id] = obj
          })
        }
      }

      this.total = res.data.total
      this.loading = false
    },
    minAmount (product) {
      if (product.amount > product.minimal_total_order_amount) {
        product.amount -= product.minimal_order_amount
      }
    },
    plusAmount (product) {
      product.amount += product.minimal_order_amount
    },
    async addProduct (product) {
      this.shoppingCart[product.id] = product.amount
      localStorage.setItem('shopping-cart', JSON.stringify(this.shoppingCart))
      await this.getShoppingCart()
      notification(this.getTranslation(product).name + ' ' + this.$t('werd toegevoegd aan je winkelwagen.'))
    },
    async getShoppingCart () {
      this.shoppingCart = JSON.parse(localStorage.getItem('shopping-cart')) || {}
      this.shoppingCartTotal = 0
      this.shoppingCartProducts = []

      for (const key of Object.keys(this.shoppingCart)) {
        const res = await ApiService.fetchProduct(key)
        const product = res.data

        if (product) {
          const amount = this.shoppingCart[product.id]
          this.shoppingCartTotal += product.unit_price * amount
        } else {
          delete this.shoppingCart[key]
          localStorage.setItem('shopping-cart', JSON.stringify(this.shoppingCart))
        }
      }
    },
    async nextPage (page) {
      this.page = page - 1
      await this.getProducts()
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.total / this.per_page)
    },
    date () {
      const date = moment().locale(this.activeLocale).format('dddd, D MMMM YYYY')
      return date.charAt(0).toUpperCase() + date.slice(1)
    }
  }
}
</script>
