import Swal from 'sweetalert2'

function notification (message, type = 'success') {
  Swal.fire({
    position: 'top-right',
    title: message,
    timer: 4000,
    icon: type,
    toast: true,
    showConfirmButton: false
  })
}

export { notification }
